import retry from 'retry';
import {OperationOptions} from 'retry';

export class AbortError extends Error {
  readonly name: 'AbortError';
  readonly originalError: Error;

  /**
	Abort retrying and reject the promise.
	@param message - An error message or a custom error.
	*/
  constructor(message: string | Error){
    super();

    if (message instanceof Error) {
      this.originalError = message;
      ({message} = message);
    } else {
      this.originalError = new Error(message);
      this.originalError.stack = this.stack;
    }

    this.name = 'AbortError';
    this.message = message;
  }
}

export interface FailedAttemptError extends Error {
	attemptNumber: number;
	retriesLeft: number;
}



export interface Options extends OperationOptions {
	/**
	Callback invoked on each retry. Receives the error thrown by `input` as the first argument with properties `attemptNumber` and `retriesLeft` which indicate the current attempt number and the number of attempts left, respectively.
	The `onFailedAttempt` function can return a promise. For example, to add a [delay](https://github.com/sindresorhus/delay):
	```
	import pRetry from 'p-retry';
	import delay from 'delay';
	const run = async () => { ... };
	const result = await pRetry(run, {
		onFailedAttempt: async error => {
			console.log('Waiting for 1 second before retrying');
			await delay(1000);
		}
	});
	```
	If the `onFailedAttempt` function throws, all retries will be aborted and the original promise will reject with the thrown error.
	*/
	readonly onFailedAttempt?: (error: FailedAttemptError) => void | Promise<void>;
}

const networkErrorMsgs = new Set([
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource.', // Firefox
  'The Internet connection appears to be offline.', // Safari
  'Network request failed', // `cross-fetch`
]);

const isNetworkError = (errorMessage:string) => networkErrorMsgs.has(errorMessage);

const decorateErrorWithCounts = (error: Error, attemptNumber: number, options: Options):FailedAttemptError  => {
  // Minus 1 from attemptNumber because the first attempt does not count as a retry
  const retriesLeft = (options.retries || 0) - (attemptNumber - 1);

  (error as any)['attemptNumber'] = attemptNumber;
  (error as any)['retriesLeft'] = retriesLeft;

  return error as FailedAttemptError;
}

export async function doRetry<T>(input: (attemptNumber?:number) => Promise<T>, options: Options):Promise<T> {
  return new Promise((resolve, reject) => {
    options = {
      onFailedAttempt: () => {},
      retries: 10,
      ...options,
    };

    const operation = retry.operation(options);

    operation.attempt(async attemptNumber => {
      try {
        resolve(await input(attemptNumber));
      } catch (error) {
        if (!(error instanceof Error)) {
          reject(new TypeError(`Non-error was thrown: "${error}". You should only throw errors.`));
          return;
        }

        if (error instanceof AbortError) {
          operation.stop();
          reject(error.originalError);
        } else if (error instanceof TypeError && !isNetworkError(error.message)) {
          operation.stop();
          reject(error);
        } else {
          const failedAttemptError = decorateErrorWithCounts(error, attemptNumber, options);

          if(options.onFailedAttempt) {
            try {
              await options.onFailedAttempt(failedAttemptError);
            } catch (error) {
              reject(error);
              return;
            }
          }

          if (!operation.retry(failedAttemptError)) {
            reject(operation.mainError());
          }
        }
      }
    });
  });
}