import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import '../App.css';
import { ApiQueryService } from '../service/api-query.service';
import { Page, List, ListItem, Link, NavTitle, Navbar} from 'framework7-react';
import { FlexBlock, FlexCol, FlexCol2, FlexRow, PhiLogo} from './RealtimeApp.component.style';
import AssetList from './AssetList.component';
import { Subject } from 'rxjs';
const Multiview: React.FC = React.memo(() => {

  const subjects = useMemo(() => {
    return {
      needRefresh: new Subject<void>(),
      clear: new Subject<void>(),
      showAssetListSettings: new Subject<void>(),
      showAssetListSettings2: new Subject<void>()
    }
  }, []);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [locationId, setLocationId] = useState<string|undefined>(undefined);
  const [locationId2, setLocationId2] = useState<string|undefined>(undefined);

  const askRefresh = useCallback(() =>
  {
    subjects.needRefresh.next()
  }, [subjects.needRefresh]);
  useInterval(askRefresh, 10000);
  
  useEffect(() => {
    async function fetchLocations() {
      await ApiQueryService.getLocations().then((res) =>
        {
          var locations = Array.from<any>(res.data).sort((x,y)=> x?.name > y?.name ? 1 : x?.name < y?.name ? -1 : 0);
          setLocations(locations);
        }
      );
    }
    fetchLocations();    
    subjects.clear.subscribe({
      next: () => { fetchLocations();}
    });
  }, [subjects.clear]);

  const reset = useCallback((x: any) => {        
  }, []);

  const onChangeLocation = (x: ChangeEvent<HTMLSelectElement>) => {
    if(locationId !== x.target.value)
    {
      setLocationId(x.target.value);
      askRefresh();
    }
  };

  const onChangeLocation2 = (x: ChangeEvent<HTMLSelectElement>) => {
   if(locationId2 !== x.target.value)
   {
     setLocationId2(x.target.value);
     askRefresh();
   }
 };

  return (
    <Page className="App Multiview">
      <Navbar>
         <NavTitle>
            <Link href="/">
               <PhiLogo/><span>IDasset {ApiQueryService.Project.toUpperCase()} </span><i>Realtime</i>
            </Link>
         </NavTitle>
      </Navbar>
      <FlexBlock>
        <FlexRow>
          <FlexCol>
            <List simpleList>
              <ListItem link className='item-input-wrap input-dropdown-wrap'>
                <select title='Location name' name='location' onChange={onChangeLocation}>
                  <option value={''}>No selection</option>
                    {
                      locations?.map((x:any, index: number) => (
                        <option key={index} value={x.id}>{x.name}</option>
                      ))
                    }     
                </select>
              </ListItem>
            </List>
          </FlexCol>
          <FlexCol>
            <List simpleList>
              <ListItem link className='item-input-wrap input-dropdown-wrap'>
                <select title='Location name' name='location2' onChange={onChangeLocation2}>
                  <option value={''}>No selection</option>
                    {
                      locations?.map((x:any, index: number) => (
                        <option key={index} value={x.id}>{x.name}</option>
                      ))
                    }     
                </select>
              </ListItem>
            </List>
          </FlexCol>
        </FlexRow>
        <FlexRow>          
          <FlexCol2 width={'45%'}>
              <FlexRow>
                <AssetList autoRefresh={subjects.needRefresh} onReset={reset} locationId={locationId}
                  showSettings={subjects.showAssetListSettings} showActions={false}/>
              </FlexRow>     
          </FlexCol2>
          <FlexCol2 width={'45%'}>
              <FlexRow>
                <AssetList autoRefresh={subjects.needRefresh} onReset={reset} locationId={locationId2}
                  showSettings={subjects.showAssetListSettings2} showActions={false}/>
              </FlexRow>    
          </FlexCol2>
        </FlexRow>   
      </FlexBlock>      
    </Page>
  );
});

export default Multiview;