import { Store, Query } from 'json-api-models';
import { Guid } from 'guid-typescript';

let defaultConfig = {
    headers: { 
      ApiKey: process.env.REACT_APP_API_KEY || '',
      Accept: 'application/vnd.api+json',
      'integratorsheader': 'include',
    }
};

const models = new Store();

export class ApiQueryService {
  static ApiUrl : string = '';
  static Project : string = '';
  static Models = models;

  static init()
  {    
    ApiQueryService.Project = new URL(document.URL).searchParams.get('project') ?? 'master';
     
    const url = process.env.REACT_APP_API_URL ?? '';
    ApiQueryService.ApiUrl = 'https://'+ ApiQueryService.Project + '.' + url + 'be/jsonapi/v1';
    //ApiQueryService.ApiUrl = 'http://localhost:7914/jsonapi/v1';
  }

  static fetch(url: string, config:any): Promise<any> {
      if(config.body)
      {
        config.body = JSON.stringify(config.body);
        config.headers['Content-Type'] = 'application/vnd.api+json';
      }
      return fetch(url, config).then(
        async (response) => {
          if (response.status === 204 || response.status === 415) {
              return { response };
          } else {
              const document = await response.json();
              if(document.errors){
                console.log(document.errors);
                return null;
              } else {                 
                const data = models.sync(document);
                return { response, document, data };
              }
          }
        }
      );
  }

  static getLastAssetEventsAfter(unixTimestamp: number): Promise<any> {
    const query = new Query({
      include: 'asset',
      filter: "expr:and(equals(eventType,'Transfer'),greaterThan(unixTimestamp,'" + (unixTimestamp-1) + "'),equals(isDeleted,'false'))",      
      sort: '-unixTimestamp',
      'page[offset]': 0,
      'page[limit]': 100
    });
    const requestUrl = ApiQueryService.ApiUrl + '/assetEvent?'+ query.toString();
     //include=' + query.include + '&filter=' + query.filter;
    return ApiQueryService.fetch(requestUrl, defaultConfig);
  }

  static getLocationAssets(locationName: string): Promise<any> {
    const requestUrl = ApiQueryService.ApiUrl + '/location/'+locationName+'?include=assets';
    return ApiQueryService.fetch(requestUrl, defaultConfig);
  }

  static getLocations(): Promise<any> {
    const requestUrl = ApiQueryService.ApiUrl + '/location';
    return ApiQueryService.fetch(requestUrl, defaultConfig);
  }

  static changeAssetLocation(assetId: string, locationId: string, method: string): Promise<any> {
    if(!locationId || locationId.length === 0)
      return Promise.reject('locationId is empty');
    const attribsLink = ApiQueryService.ApiUrl + '/asset/' + assetId + '/relationships/attribs';
    const options = {
      ...defaultConfig,
      method: method,
      body:{
        data: [{
          id: locationId,
          type: 'location'
        }]
      }
    };
    return ApiQueryService.fetch(attribsLink, options);
  }

  static createAssetEventTransfer(asset: any, before: string, after: string): Promise<any> {
    if(!before || before.length === 0)
      return Promise.reject('before is empty');
    if(!after || after.length === 0)
      return Promise.reject('after is empty');
    const attribsLink = ApiQueryService.ApiUrl + '/assetEvent';
    const options = {
      ...defaultConfig,
      method: 'POST',
      body:{
        data: {
          id: Guid.raw(),
          type: 'assetEvent',
          attributes: {
            eventType: 'Transfer',
            before: before,
            after: after,
            unixTimestamp: Date.now()
          },
          relationships: {
            asset: {
              data: {
                ...asset.identifier()
              }
            }
          }
        }
      }
    };
    return ApiQueryService.fetch(attribsLink, options);
  }

  static deleteAssetLocation(asset: any, locationId: string): Promise<any> {
    return ApiQueryService.changeAssetLocation(asset.id, locationId, 'DELETE');
  }

  static postAssetLocation(asset: any, locationId: string): Promise<any> {
    return ApiQueryService.changeAssetLocation(asset.id, locationId, 'POST');
  }

  static resetAssetLocation(asset: any, currentLocationId: string, resetLocationId: string) : Promise<any> {
    return ApiQueryService.deleteAssetLocation(asset, currentLocationId)
    .then(() => ApiQueryService.postAssetLocation(asset, resetLocationId))
    .then(() => ApiQueryService.createAssetEventTransfer(asset, currentLocationId, resetLocationId))
    .catch(e => { console.log(e)});
  }

  static async moveAssetsToLocation(currentLocationId: string, moveLocationId: string) : Promise<any> {
    var location = models.find('location', currentLocationId);
    if(!location)
    {
      await ApiQueryService.getLocationAssets(currentLocationId);
      location = models.find('location', currentLocationId);
    }
    if(location?.assets?.length)
    {
      location.assets.forEach((asset: any) => {
        ApiQueryService.resetAssetLocation(asset, currentLocationId, moveLocationId)
        .catch(e => { console.log(e)});;
      });
    }
  }
}