import styled from 'styled-components';
import { ReactComponent as PHIDATA } from '../icons/phidata_vectorized.svg';
import { ReactComponent as TRUCK } from '../icons/delivery-truck.svg';

type JustifyContentProp = 'center' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right' |
'normal' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';

interface FlexedItemProps {
    width?: number | string;
    grow?: number;
    shrink?: number;
    basis?: string;
    mobileDirection?: string;
    justifyContent?: JustifyContentProp
    alignItems?: string;
}

export const PhiLogo = styled(PHIDATA)`
    height: 1.1em;
    width: 1.1em;
    padding: 0 !important;
`;

export const TruckIcon = styled(TRUCK)`
    height: 35px;
    width: 60px;
    padding: 0 !important;
`;

export const FlexItem = styled.div<FlexedItemProps>`
    width: ${(props => props.width ? props.width : 'auto' || 'auto')};
    display: flex;  
    flex-grow: ${(props => props.grow ? props.grow : 1 || 1)};
    flex-shrink: ${(props => props.shrink ? props.shrink : 1 || 1)};
    flex-basis: ${(props => props.basis ? props.basis : 'auto' || 'auto')};
    justify-content: ${(props => props.justifyContent ? props.justifyContent : 'normal' || 'normal')};
    align-items: ${(props => props.alignItems ? props.alignItems : 'normal' || 'normal')};
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: ${(props => props.mobileDirection ? props.mobileDirection : 'column' || 'column')};
    }
`;
export const FlexBlock = styled(FlexItem)`
    flex-direction: column;
    padding: 0;
`;

export const FlexRow = styled(FlexItem)`
`;

export const FlexCol = styled(FlexItem)`
    @media (min-width: 768px) {
        padding: 0 5px;
    }
`;

export const FlexCol2 = styled(FlexItem)`
    flex-direction: column !important;
    flex-
`;

interface SearchableProps {
    hidden?: boolean;
    match?: boolean;
}
export const SearchableRow = styled.tr<SearchableProps>`
    ${(props:any) => {
        switch (props.hidden) {
            case 'true':
            case true:
                return `
                display: none;
                `;
            default:
                if(props.match)
                    return `
                        background-color: lightgreen;
                    `;
                return ``;
        }
    }}
`;

export const SearchableCell = styled.td<SearchableProps>`
    ${(props:any) => {
        switch (props.hidden) {
            case 'true':
            case true:
                return `
                display: none;
                `;
            default:
                if(props.match)
                    return `
                        background-color: lightgreen;
                    `;
                return ``;
        }
    }}
`;