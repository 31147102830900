import { ColumnDef, ColumnFiltersState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, PaginationState, SortingState, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useMemo } from "react";
import { Subject } from "rxjs";
import { useInterval } from "usehooks-ts";
import { Filter } from "../helpers/table.helper";
import { ApiQueryService } from "../service/api-query.service";
import { NotificationService } from "../service/notifications.service";
import { FlexRow, SearchableRow } from "./RealtimeApp.component.style";

const maxEvents = 1000;

const AssetEventsList = React.memo(({
        autoRefresh,
        counter,
        clear,
        onReset,
        onlyIn,
        locationId,
        showSettings
    }:{
        autoRefresh: Subject<void>,
        counter : Subject<number>,
        clear: Subject<void>,
        onReset: (x: any) => void,
        onlyIn: boolean,
        locationId: string|undefined,
        showSettings: Subject<void>
    }) => {

    const [lastTimestamp, setLastTimestamp] = React.useState<number>(Date.now());
    const [events, setEvents] = React.useState<Array<any>>([]);
    const [needRefresh, setNeedRefresh] = React.useState<boolean>(false);
    const [sorting, setSorting] = React.useState<SortingState>([{id:"Date", desc: true}]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [settingsModal, setSettingsModal] = React.useState<boolean>(false);
    const [{pageIndex, pageSize}, setPagination] = React.useState<PaginationState>({pageIndex: 0, pageSize: 20});

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
    )
    
    useEffect(() =>
    {
      setPagination({pageIndex : 0, pageSize: 20});
      setNeedRefresh(true);
    }, [locationId]);

    NotificationService.notificationReceived.subscribe({
      next: () => setNeedRefresh(true)
    });

    autoRefresh.subscribe({
      next: () => setNeedRefresh(true)
    });

    clear.subscribe({
      next: () => { setLastTimestamp(Date.now()); setEvents([]); counter.next(0);}
    });

    showSettings.subscribe({
      next: () => setSettingsModal(true)
    });

    useEffect(() =>
    {
      function updateCounter() {
        let count = 0;
        if(!onlyIn){
          count = events.length;
        }else{
          events.forEach((e: any) => {
            if(e.after === locationId)
              count++;
          });
        }
        counter.next(count);
      }

      updateCounter();
    }, [onlyIn, counter, events, locationId]);

    const getAssetEvents = () =>
    {
      async function fetchAssetEvents() {
        await ApiQueryService.getLastAssetEventsAfter(lastTimestamp).then((res) =>
          {
            if(res?.data){
              const dataEvents = res.data as any[];
              if(dataEvents && dataEvents.length > 0){              
                const allEvents = ApiQueryService.Models.findAll('assetEvent');
                var topEvents = allEvents.length > maxEvents ? allEvents.slice(-maxEvents) : allEvents;
                topEvents.sort((a,b) => b.unixTimestamp - a.unixTimestamp);
                setEvents(topEvents);
                if(events[0]?.unixTimestamp)
                  setLastTimestamp(events[0].unixTimestamp);
              }
            }
            if(needRefresh)
            {
              setNeedRefresh(false);
            }
            //updateCounter();
          }
        );
      }
      if(needRefresh){   
        fetchAssetEvents();
      }
    };
    useInterval(getAssetEvents, 1000);

    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [
            {
                header: 'Date',
                accessorFn: row=> row?.unixTimestamp,
                cell: info => (new Date(info.getValue<string>())).toLocaleString(),
                enableColumnFilter: false,
                enableSorting: true,
                sortDescFirst: true
            },
            {
                header: 'Type',
                accessorFn: row=> row?.eventType,
                cell: info => info?.getValue<string>(),
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: 'Asset Nr',
                accessorFn: row=> row?.asset?.assetNumber,
                cell: info => info?.getValue<string>(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {
              header: 'Asset Name',
              accessorFn: row=> row?.asset?.name,
              cell: info => info?.getValue<string>(),
              filterFn: 'includesString',
              enableColumnFilter: true,
              enableSorting: true
            },
            {
                header: 'Destination',
                accessorFn: row=> ApiQueryService.Models.find('location',(row?.after))?.name,
                cell: info => info?.getValue<any>() ?? 'n/a',
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {                
                header: 'Actions',
                accessorFn: row => row.asset,
                cell: info => <div><button onClick={() => onReset(info.getValue())} className="button button-fill">BACK<i className='space-left icon f7-icons arrow_uturn_left_circle'/></button></div>,
                enableColumnFilter: false,
                enableSorting: false
            }
        ],
        [onReset]
    );
    const dataQuery = useMemo(
      () => {
        return {
          rows: events.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize
          ),
          pageCount: Math.max(1,Math.ceil(events.length / pageSize)),
        }
      },
      [events, pageIndex, pageSize],
    )

    const defaultData = React.useMemo(() => [], []);

    const table = useReactTable({
        data: dataQuery?.rows ?? defaultData,
        pageCount: dataQuery?.pageCount ?? 1,
        columns,
        state: {
          sorting,
          columnFilters,
          pagination
        },
        enableMultiSort: true,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: true,
        debugTable: true,
    });

    const onCloseModal = (e:any) => {
      setSettingsModal(false);
    };

    return (
      <div className="events-list scroll-table">
        <div className={settingsModal?'list modal':'hidden'}>
          <ul className="settings list simple-list">
            <li>
              <h3>Event columns</h3>
              <span className="button button-fill button-small close-button action-button" onClick={onCloseModal}>X</span>
            </li>
            <li>
              <label className="item-checkbox item-content">
                <input
                  {...{
                    type: 'checkbox',
                    checked: table.getIsAllColumnsVisible(),
                    onChange: table.getToggleAllColumnsVisibilityHandler(),
                  }}
                />{' '}
                <i className="icon-checkbox"></i>
                <div className="item-inner">
                  <div className="item-title">Toggle All</div>
                </div>
              </label>
            </li>
          {table.getAllLeafColumns().map(column => {
            return (
              <li key={column.id}>
                <label className="item-checkbox item-content">
                  <input
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{' '}
                  <i className="icon-checkbox"></i>
                  <div className="item-inner">
                    <div className="item-title">{column.id}</div>
                  </div>
                </label>
              </li>
            )
          })}
          </ul> 
        </div>
        <table className="data-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => ( 
              <tr key={headerGroup.id} className='fixed'>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column}/>
                            </div>
                          ) : null}
                        </>
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <SearchableRow match={!onlyIn && (row.original.after) === locationId} hidden={onlyIn && row.original.after !== locationId} key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </SearchableRow>
            ))}
          </tbody>
        </table>
        <div className="fixed-bot">
          <FlexRow alignItems="center" justifyContent="space-around" mobileDirection="row">
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span>
              <span>Page </span>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <select className="link" title="Page Size"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50,100,1000].map(pageSize => (
                <option key={pageSize} value={pageSize} label={"Show " + pageSize.toString()}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <div>{table.getRowModel().rows.length} Rows</div>
          </FlexRow>
        </div>
      </div>
    );
});

export default AssetEventsList;
