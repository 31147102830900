import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Framework7 from 'framework7/lite';
import 'framework7/css/bundle';
import 'framework7-icons';
// Import Framework7 React
import Framework7React from 'framework7-react';

import AppContainer from './components/AppContainer.component';
import { ApiQueryService } from './service/api-query.service';
import { NotificationService } from './service/notifications.service';

// Init plugin
Framework7.use(Framework7React);

ApiQueryService.init();
NotificationService.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
