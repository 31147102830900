import { App as F7App, View, Views } from 'framework7-react';
import Multiview from './Multiview.component';
import RealtimeApp from './RealtimeApp.component';

const f7params = {
    name: 'IDasset realtime',
    id: 'com.phidata.idasset.realtime',
    // specify routes for app
    routes: [
      {
        path: '/',
        component: RealtimeApp,
      },
      {
        path: '/compare/',
        component: Multiview
      }
    ],
  };
  
const AppContainer: React.FC = () => {
    return (
        <F7App { ...f7params }>
          <Views>
            <View main url="/" />
          </Views>            
        </F7App>
    );
};

export default AppContainer;
