import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import '../App.css';
import { ApiQueryService } from '../service/api-query.service';
import { Page, List, ListItem, Checkbox, Icon, Link, Button, NavRight, Navbar, NavTitle} from 'framework7-react';
import { FlexBlock, FlexCol, FlexCol2, FlexRow, PhiLogo, TruckIcon } from './RealtimeApp.component.style';
import AssetList from './AssetList.component';
import LiveView from './LiveView.component';
import AssetEventsList from './AssetEventsList.component';
import { Subject } from 'rxjs';
const RealtimeApp: React.FC = React.memo(() => {

  const subjects = useMemo(() => {
    return {
      counter: new Subject<number>(),
      clear: new Subject<void>(),
      needRefresh: new Subject<void>(),
      showAssetListSettings: new Subject<void>(),
      showEventListSettings: new Subject<void>(),
      showLiveListSettings: new Subject<void>(),
    }
  }, []);
  const [counter, setCounter] = useState<number>(0);
  const [onlyIn, setOnlyIn] = useState<boolean>(true);
  const [showLive, setShowLive] = useState<boolean>(false);
  const [showAssetList, setShowAssetList] = useState<boolean>(false);
  const [showEventList, setShowEventList] = useState<boolean>(false);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [locationId, setLocationId] = useState<string|undefined>(undefined);
  const [ignoreGateTransfer, setIgnoreGateTransfer] = useState<boolean>(false);
  const [resetLocationId, setResetLocationId] = useState<string|undefined>(undefined);
  const [moveLocationId, setMoveLocationId] = useState<string|undefined>(undefined);

  subjects.counter.subscribe({
    next : x => setCounter(x)
  });
  const askRefresh = useCallback(() =>
  {
    subjects.needRefresh.next()
  }, [subjects.needRefresh]);
  useInterval(askRefresh, 10000);
  
  useEffect(() => {
    async function fetchLocations() {
      await ApiQueryService.getLocations().then((res) =>
        {
          var locations = Array.from<any>(res.data).sort((x,y)=> x?.name > y?.name ? 1 : x?.name < y?.name ? -1 : 0);
          setLocations(locations);
        }
      );
    }
    fetchLocations();    
    subjects.clear.subscribe({
      next: () => { fetchLocations();}
    });
  }, [subjects.clear]);

  const reset = useCallback((x: any) => {    
    async function resetLocation()
    {
      if(x && locationId && resetLocationId){
        await ApiQueryService.resetAssetLocation(x, locationId, resetLocationId);
        askRefresh();
      }
    }
    resetLocation();
  }, [locationId, resetLocationId, askRefresh]);

  const moveAll = useCallback(() => {    
    async function moveAllAssets()
    {
      if(locationId && moveLocationId){
        await ApiQueryService.moveAssetsToLocation(locationId, moveLocationId);
        askRefresh();
      }
    }
    moveAllAssets();
  }, [locationId, moveLocationId, askRefresh]);

  const onChangeLocation = (x: ChangeEvent<HTMLSelectElement>) => {
    if(locationId !== x.target.value)
    {
      setLocationId(x.target.value);
      const ignore : boolean = ApiQueryService.Models.find('location', x.target.value)?.ignoreGateTransfer ?? false;
      setIgnoreGateTransfer(ignore);
      askRefresh();
    }
  };

  const onChangeResetLocation = (x: ChangeEvent<HTMLSelectElement>) => {
    setResetLocationId(x.target.value);
  };

  const onChangeMoveLocation = (x: ChangeEvent<HTMLSelectElement>) => {
    setMoveLocationId(x.target.value);
  };

  const resetCounter = () => {    
    ApiQueryService.Models.reset();
    subjects.clear.next();
  };

  const onChangeOnlyIn = () => {
    setOnlyIn(val => !val);
  };

  const onChangeShowLive = (e:any) => {
    setShowLive(val => !val);
  };

  const onChangeShowAssetList = (e:any) => {
    setShowAssetList(val => !val);
  };

  const onChangeShowEventList = (e:any) => {
    setShowEventList(val => !val);
  };

  const onClickAssetListSettings = (e:any) => {
    subjects.showAssetListSettings.next();
  };

  const onClickEventListSettings = (e:any) => {
    subjects.showEventListSettings.next();
  };

  const onClickLiveListSettings = (e:any) => {
    subjects.showLiveListSettings.next();
  };

  return (
    <Page className="App">
      <Navbar>
         <NavTitle>
            <Link href="/">
               <PhiLogo/><span>IDasset {ApiQueryService.Project.toUpperCase()} </span><i>Realtime</i>
            </Link>
         </NavTitle>
         <NavRight>
            <Button href="/compare/" fill className='space-right'>Compare</Button>
         </NavRight>
      </Navbar>
      <FlexBlock>
        <FlexRow>
          <FlexCol>
            <List simpleList>
              <ListItem>
                  <h3>Location</h3>
              </ListItem>
              <ListItem link className='item-input-wrap input-dropdown-wrap'>
                <select title='Location name' name='location' onChange={onChangeLocation}>
                  <option value={''}>No selection</option>
                    {
                      locations?.map((x:any, index: number) => (
                        <option key={index} value={x.id}>{x.name}</option>
                      ))
                    }     
                </select>
              </ListItem>
              <ListItem>
                <div>Ignore notifications</div>
                <Checkbox disabled checked={ignoreGateTransfer}/>
              </ListItem>
              <ListItem link className='item-input-wrap input-dropdown-wrap'>
                <Icon f7='arrow_uturn_left_circle'/>
                <span className='space-left space-right'>Back to</span>
                <select name='location' title="location" onChange={onChangeResetLocation}>
                  <option value={''}>No selection</option>
                    {
                      locations?.map((x:any, index: number) => (
                        <option key={index} value={x.id}>{x.name}</option>
                      ))
                    }     
                </select>
              </ListItem>
              <ListItem >
                <TruckIcon/>
                <span className='space-left space-right'>Move to</span>
                <ListItem link className='item-input-wrap input-dropdown-wrap'>
                  <select name='location' title="location" onChange={onChangeMoveLocation}>
                    <option value={''}>No selection</option>
                      {
                        locations?.map((x:any, index: number) => (
                          <option key={index} value={x.id}>{x.name}</option>
                        ))
                      }     
                  </select>
                </ListItem>
                <button onClick={() => moveAll()} className='button space-left text-color-red'>Move all</button>
              </ListItem>           
            </List>
          </FlexCol>
          <FlexCol>
            <List simpleList>
              <ListItem>
                  <h3>Event info</h3>
              </ListItem>
              <ListItem>
                <div>Counter</div>
                <div>{counter}</div>
                <div><button className='button' onClick={resetCounter}>CLEAR</button></div>
              </ListItem>
              <ListItem>
                <div>Only IN</div>
                <Checkbox checked={onlyIn} onChange={onChangeOnlyIn}/>
              </ListItem>
              <ListItem>
                <div>Show asset list</div>
                <Checkbox checked={showAssetList} onChange={onChangeShowAssetList}/>
                <div>Show event list</div>
                <Checkbox checked={showEventList} onChange={onChangeShowEventList}/>
              </ListItem>
              <ListItem>
                <div>Show live notifications</div>
                <Checkbox checked={showLive} onChange={onChangeShowLive}/>
              </ListItem>
            </List>
          </FlexCol>
        </FlexRow>
        <FlexRow mobileDirection='column-reverse'>          
          <FlexCol2 grow={22} shrink={0} className={showAssetList?'':'hidden'}>
              <FlexRow justifyContent="center" alignItems="center">
                  <h3>Asset list</h3><span onClick={onClickAssetListSettings} className="action-button"><Icon f7='gear_alt'/></span>
              </FlexRow>
              <FlexRow>
                <AssetList autoRefresh={subjects.needRefresh} onReset={reset} locationId={locationId}
                  showSettings={subjects.showAssetListSettings} showActions={true}/>
              </FlexRow>              
          </FlexCol2>
          <FlexCol2 grow={88} shrink={0} className={showEventList?'':'hidden'}>
              <FlexRow justifyContent="center" alignItems="center">
                <h3>Events</h3><span onClick={onClickEventListSettings} className="action-button"><Icon f7='gear_alt'/></span>
              </FlexRow>
              <FlexRow>
                <AssetEventsList autoRefresh={subjects.needRefresh} clear={subjects.clear} onReset={reset} onlyIn={onlyIn} locationId={locationId}
                  counter={subjects.counter} showSettings={subjects.showEventListSettings}/>
              </FlexRow>
          </FlexCol2>
        </FlexRow>
        {!showLive ? null : (
        <FlexRow>
          <FlexCol2>
            <FlexRow justifyContent="center" alignItems="center">
              <h3>Notifications</h3><span onClick={onClickLiveListSettings} className="action-button"><Icon f7='gear_alt'/></span>
            </FlexRow>
            <FlexRow>
              <LiveView clear={subjects.clear} onlyIn={onlyIn} locationId={locationId} showSettings={subjects.showLiveListSettings}/>
            </FlexRow>
          </FlexCol2>
        </FlexRow>
        )}        
      </FlexBlock>      
    </Page>
  );
});

export default RealtimeApp;
