import * as SignalR from '@microsoft/signalr';
import { doRetry } from '../helpers/retry.helper';
import { Subject } from 'rxjs';

const RECONNECT_DELAY_MILLISECONDS = 20000;
const maxNotifications = 1000;

export type OnNotificationCallBack = (notif: any) => void;

export class NotificationService {
  static connection: SignalR.HubConnection;
  static initialized: boolean;
  static ApiUrl : string = '';
  static Project : string = '';
  static onNotificationCallback : OnNotificationCallBack | undefined = undefined;  
  static notifications : Array<any> = [];
  static notificationReceived = new Subject<TransferRequestDto>();

  static init()
  {
    if (NotificationService.initialized) {
      console.warn('PrintService is already initialized');
      return Promise.resolve();
    }

    NotificationService.Project = new URL(document.URL).searchParams.get('project') ?? 'master';
    const url = process.env.REACT_APP_API_URL ?? '';
    NotificationService.ApiUrl = 'https://'+ NotificationService.Project + '.' + url + 'be/';    
    //NotificationService.ApiUrl = 'http://localhost:7914/';

    if(!NotificationService.ApiUrl)
    {
      console.error('Empty NotificationService.ApiUrl');
      throw new Error('Empty NotificationService.ApiUrl');
    }

    NotificationService.connection = new SignalR.HubConnectionBuilder()
      .withUrl(`${NotificationService.ApiUrl || ''}realtimeHub`, {
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
        accessTokenFactory: ()=>process.env.REACT_APP_API_KEY || ''
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext: SignalR.RetryContext)=> {
          console.debug(`Try reconnect in ${RECONNECT_DELAY_MILLISECONDS} ms`,retryContext);
          if(retryContext.previousRetryCount === 6) {
            console.error('Notification service is offline')
          }
          return RECONNECT_DELAY_MILLISECONDS;
        },

      })
      .configureLogging(SignalR.LogLevel.Trace)
      .build();
  
    configureHub(NotificationService.connection);
    this.initialized = true;
    return doRetry(
      async () => await NotificationService.connection.start(),
      {
        onFailedAttempt: (error) => console.debug('SignalR connection start error!', 'Retry attempt.', error.attemptNumber, error.retriesLeft,error),
        forever: true
      }
    );
  }

  static onNotificationReceived = async (notif: TransferRequestDto)  => {
    NotificationService.notifications.push(notif);
    if(NotificationService.notifications.length > maxNotifications){
      NotificationService.notifications = NotificationService.notifications.slice(-maxNotifications);
    }
    NotificationService.notificationReceived.next(notif);
  }
}

export class TransferRequestDto{
  status : string | undefined;
  correlationId : string | undefined;
}

export const configureHub = (connection: SignalR.HubConnection) => {
  connection.on('onError', async (errorData: Error) => {
    console.error('OnError Handling', errorData);
  });

  connection.onclose((error: Error|undefined) => {
    console.error('onclose', error);
  })

  connection.onreconnecting((error: Error|undefined) => {
    console.debug('onreconnecting', error);
  })

  connection.onreconnected((connectionId?: string | undefined) => {
    console.debug('onreconnected', connectionId);
  })

  connection.on('OnTransferRequested', async (request: TransferRequestDto) => 
  {
    console.log('OnTransferRequested Handling', request);
    
    await NotificationService.onNotificationReceived(request);
  });
};