import { ColumnDef, ColumnFiltersState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, PaginationState, SortingState, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useMemo } from "react";
import { Subject } from "rxjs";
import { useInterval } from "usehooks-ts";
import { Filter } from "../helpers/table.helper";
import { ApiQueryService } from "../service/api-query.service";
import { NotificationService } from "../service/notifications.service";
import { FlexRow } from "./RealtimeApp.component.style";

const maxAssets = 1000;

const AssetList = React.memo(({
      autoRefresh,
      onReset,
      locationId,
      showSettings,
      showActions
    }:{
      autoRefresh: Subject<void>,
      onReset: (x: any) => void,
      locationId: string|undefined,
      showSettings: Subject<void>,
      showActions: boolean
    }) => {

    const [assets, setAssets] = React.useState<Array<any>>([]);
    const [needRefresh, setNeedRefresh] = React.useState<boolean>(false);
    const [sorting, setSorting] = React.useState<SortingState>([{id:"Timestamp", desc: true}]);
    const [{pageIndex, pageSize}, setPagination] = React.useState<PaginationState>({pageIndex: 0, pageSize: 20});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [settingsModal, setSettingsModal] = React.useState<boolean>(false);
    const [optionMaxAssets, setOptionMaxAssets] = React.useState<number>(maxAssets); 

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
    )
    
    NotificationService.notificationReceived.subscribe({
      next: () => setNeedRefresh(true)
    });
    
    autoRefresh.subscribe({
      next: () => setNeedRefresh(true)
    });

    showSettings.subscribe({
      next: () => setSettingsModal(true)
    });

    useEffect(() =>{
      setNeedRefresh(true)
    }, [locationId]);

    const getAssets = () =>
    {
      async function fetchAssets() {
        if(needRefresh)
          setNeedRefresh(false);
        if(locationId && locationId.length > 0)
          await ApiQueryService.getLocationAssets(locationId).then((res) =>
            {
              if(res?.data && res.data.assets?.length > 0)
              {
                var assetsRes = Array.from<any>(res.data.assets);
                setAssets(assetsRes.length > optionMaxAssets ? assetsRes.slice(-optionMaxAssets) : assetsRes);
              }
              else if(assets.length > 0){                
                setPagination({pageIndex : 0, pageSize: pageSize});
                setAssets([]);
              }
            }
          );
        else{
          setPagination({pageIndex : 0, pageSize: pageSize});
          setAssets([]);
        }
      }
      if(needRefresh){   
        fetchAssets();
        const columns = table?.getAllLeafColumns();
        if(!showActions){
          columns[2]?.toggleVisibility(showActions);//hide timestamp in multiview
          columns[3]?.toggleVisibility(showActions);//hide actions in multiview
        }
      }
    };
    useInterval(getAssets, 1000);
    
    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [
            {
                header: 'Asset Number',
                accessorFn: row=> row?.assetNumber,
                cell: info => info?.getValue(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {
              header: 'Asset Name',
                accessorFn: row=> row?.name,
                cell: info => info?.getValue(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {
              header: 'Timestamp',
                accessorFn: row=> row?.timestamp,
                cell: info => info?.getValue(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {                
                header: 'Actions',
                accessorFn: row => row,
                cell: info => <div><button onClick={() => onReset(info.getValue())} className="button button-fill">BACK<i className='space-left icon f7-icons arrow_uturn_left_circle'/></button></div>,
                enableColumnFilter: false,
                enableSorting: false,
            }
        ],
        [onReset]
    );
    const dataQuery = useMemo(
      () => {
        return {
          rows: assets.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize
          ),
          pageCount: Math.max(1,Math.ceil(assets.length / pageSize)),
        }
      },
      [assets, pageIndex, pageSize],
    )

    const defaultData = React.useMemo(() => [], []);

    const table = useReactTable({
        data: dataQuery?.rows ?? defaultData,
        pageCount: dataQuery?.pageCount ?? 1,
        columns,
        state: {
          sorting,
          columnFilters,
          pagination
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: true,
        debugTable: true,
    });

    const onCloseModal = (e:any) => {
      setSettingsModal(false);
    };

    return (
      <div className="asset-list scroll-table">
        <div className={settingsModal?'list modal':'hidden'}>
          <ul className="settings list simple-list">
            <li>
              <h3>Asset columns</h3>
              <span className="button button-fill button-small close-button action-button" onClick={onCloseModal}>X</span>
            </li>
            <li>
              <label className="item-checkbox item-content">
                <input
                  {...{
                    type: 'checkbox',
                    checked: table.getIsAllColumnsVisible(),
                    onChange: table.getToggleAllColumnsVisibilityHandler(),
                  }}
                />{' '}
                <i className="icon-checkbox"></i>
                <div className="item-inner">
                  <div className="item-title">Toggle All</div>
                </div>
              </label>
            </li>
          {table.getAllLeafColumns().map(column => {
            return (
              <li key={column.id}>
                <label className="item-checkbox item-content">
                  <input
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{' '}
                  <i className="icon-checkbox"></i>
                  <div className="item-inner">
                    <div className="item-title">{column.id}</div>
                  </div>
                </label>
              </li>
            )
          })}
          <li><h3>Options</h3></li>
          <li>
            <label className="item-textbox item-content">
              <input type="number" min={0} max={maxAssets} defaultValue={optionMaxAssets} onChange={(e) =>setOptionMaxAssets(e?.target?.valueAsNumber)}/>
              <div className="item-title">Max assets</div>
            </label>
          </li>
          </ul> 
        </div>
        <table className="data-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => ( 
              <tr key={headerGroup.id} className="fixed">
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column}/>
                            </div>
                          ) : null}
                        </>
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="fixed-bot">
          <FlexRow alignItems="center" justifyContent="space-around" mobileDirection="row">
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span>
              <span>Page </span>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <select className="link" title="Page Size"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 15, 20, 25, 30, 40, 50,100,1000].map(pageSize => (
                <option key={pageSize} value={pageSize} label={"Show " + pageSize.toString()}>
                  Show {pageSize}
                </option>
              ))}
            </select> 
            <div>{table.getRowModel().rows.length} Rows</div>
        </FlexRow>
      </div>
    </div>
    );
});

export default AssetList;