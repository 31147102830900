import { ColumnDef, ColumnFiltersState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getSortedRowModel, PaginationState, SortingState, useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useInterval } from 'usehooks-ts';
import { Filter } from '../helpers/table.helper';
import { ApiQueryService } from '../service/api-query.service';
import { NotificationService } from '../service/notifications.service';
import { FlexRow, SearchableRow } from './RealtimeApp.component.style';
import { Subject } from "rxjs";

const LiveView = React.memo(({
      clear,
      onlyIn,
      locationId,
      showSettings
    }:{
      clear: Subject<void>,
      onlyIn: boolean,
      locationId: string|undefined,
      showSettings: Subject<void>
    }) => {
    
    const [data, setData] = React.useState<any[]>(JSON.parse(JSON.stringify(NotificationService.notifications)));
    const [needRefresh, setNeedRefresh] = React.useState<boolean>(false);
    const [sorting, setSorting] = React.useState<SortingState>([{id:"Date", desc: true}]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [settingsModal, setSettingsModal] = React.useState<boolean>(false);
    const [{pageIndex, pageSize}, setPagination] = React.useState<PaginationState>({pageIndex: 0, pageSize: 20});

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
    )

    showSettings.subscribe({
      next: () => setSettingsModal(true)
    });

    clear.subscribe({
      next: () => { NotificationService.notifications = []; setData([]);}
    });
    NotificationService.notificationReceived.subscribe({
      next: () => setNeedRefresh(true)
    });

    const tryRefreshData = () =>
    {
      function refreshData() {
        var jsonClone = JSON.parse(JSON.stringify(NotificationService.notifications));
        setData(jsonClone);
        if(needRefresh)
          setNeedRefresh(false);
      }
      if(needRefresh){
        refreshData();
      }
    };
    useInterval(tryRefreshData, 1000);

    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [
            {
                header: 'Date',
                accessorFn: row=> row.unixTimestamp,
                cell: info => (new Date(info.getValue<string>())).toLocaleString(),
                enableColumnFilter: false,
                enableSorting: true,
                sortDescFirst: true
            },
            {
                header: 'State',
                accessorFn: row=> row.reason,
                cell: info => info.getValue<string>(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {
                header: 'EPC',
                accessorFn: row=> row.epc,
                cell: info => info.getValue<string>(),
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            },
            {
                header: 'Rssi',
                accessorFn: row=> row.rssi,
                cell: info => info.getValue<string>(),
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: 'Location',
                accessorFn: row=> ApiQueryService.Models.find('location',(row.location))?.name,
                cell: info => info.getValue<any>() ?? 'n/a',
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: true
            }
        ],
        []
    );

    const dataQuery = useMemo(
      () => {
        return {
          rows: data.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize
          ),
          pageCount: Math.max(1,Math.ceil(data.length / pageSize)),
        }
      },
      [data, pageIndex, pageSize],
    )

    const defaultData = React.useMemo(() => [], []);

    const table = useReactTable({
        data : dataQuery?.rows ?? defaultData,
        pageCount: dataQuery?.pageCount ?? 1,
        columns,
        state: {
          sorting,
          columnFilters,
          pagination
        },
        enableMultiSort: true,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: true,
        debugTable: true,
    });

    const onCloseModal = (e:any) => {
      setSettingsModal(false);
    };

    return (
      <div className="live-list scroll-table">
        <div className={settingsModal?'list modal':'hidden'}>
          <ul className="settings list simple-list">
            <li>
              <h3>Notification columns</h3>
              <span className="button button-fill button-small close-button action-button" onClick={onCloseModal}>X</span>
            </li>
            <li>
              <label className="item-checkbox item-content">
                <input
                  {...{
                    type: 'checkbox',
                    checked: table.getIsAllColumnsVisible(),
                    onChange: table.getToggleAllColumnsVisibilityHandler(),
                  }}
                />{' '}
                <i className="icon-checkbox"></i>
                <div className="item-inner">
                  <div className="item-title">Toggle All</div>
                </div>
              </label>
            </li>
          {table.getAllLeafColumns().map(column => {
            return (
              <li key={column.id}>
                <label className="item-checkbox item-content">
                  <input
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{' '}
                  <i className="icon-checkbox"></i>
                  <div className="item-inner">
                    <div className="item-title">{column.id}</div>
                  </div>
                </label>
              </li>
            )
          })}
          </ul> 
        </div>
      <table className="data-table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => ( 
            <tr key={headerGroup.id} className="fixed">
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column}/>
                          </div>
                        ) : null}
                      </>
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>          
          {table.getRowModel().rows.map(virtualRow => {
              const row = virtualRow
              return (
                <SearchableRow match={!onlyIn && (row.original.location) === locationId} hidden={onlyIn && row.original.location !== locationId} key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </SearchableRow>
              )
          })}
        </tbody>
      </table>
      <div className="fixed-bot">
          <FlexRow alignItems="center" justifyContent="space-around" mobileDirection="row">
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="button button-small"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span>
              <span>Page </span>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <select className="link" title="Page Size"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50,100,1000].map(pageSize => (
                <option key={pageSize} value={pageSize} label={"Show " + pageSize.toString()}>
                  Show {pageSize}
                </option>
              ))}
            </select> 
            <div>{table.getRowModel().rows.length} Rows</div>
        </FlexRow>
      </div>
      </div>
    );
});

export default LiveView;